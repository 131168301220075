var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.roundNum
        ? _c("InfoAlert", [
            _vm._v(" Please, select a round from the timeline above ")
          ])
        : [
            _c("SectionTitle", [_vm._v("Round video")]),
            _c("MatchVideoPage", {
              attrs: { "match-id": _vm.matchId, "round-num": _vm.roundNum }
            }),
            _c("SectionTitle", [_vm._v("Round stats")]),
            _c("MatchRoundStatsPage", {
              attrs: { "match-id": _vm.matchId, "round-num": _vm.roundNum }
            })
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }