var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppTable", {
    staticClass: "round-stats-table",
    class: _vm.tableClass,
    attrs: { fields: _vm.fields, items: _vm.items, busy: _vm.busy },
    scopedSlots: _vm._u([
      {
        key: "head(loadout)",
        fn: function(data) {
          return [_c("span", { domProps: { innerHTML: _vm._s(data.label) } })]
        }
      },
      {
        key: "cell(player)",
        fn: function(data) {
          return [
            _c("img", {
              staticClass: "table-icon agent",
              class: _vm.variant,
              attrs: {
                src: _vm.getAgentThumbnail(data.item.agent_name),
                alt: data.item.agent_name
              }
            }),
            _vm.variant === "slim"
              ? _c("div", [_vm._v(" " + _vm._s(data.item.name) + " ")])
              : _vm._e(),
            _vm.variant === "reg"
              ? _c("span", [_vm._v(_vm._s(data.item.name))])
              : _vm._e()
          ]
        }
      },
      {
        key: "head(ultimates)",
        fn: function(data) {
          return [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.focus.noninteractive",
                    modifiers: { focus: true, noninteractive: true }
                  }
                ],
                attrs: { title: "Orbs data from start of round" }
              },
              [_vm._v(" " + _vm._s(data.label) + " ")]
            )
          ]
        }
      },
      {
        key: "cell(ultimates)",
        fn: function(data) {
          return [
            data.item.ultimates && data.item.ultimates.count !== null
              ? _c("div", [
                  data.item.ultimates.count !== null &&
                  data.item.ultimates.count === data.item.ultimates.max
                    ? _c("img", {
                        staticClass: "table-icon",
                        attrs: {
                          src: _vm.ultimateImageUrl(data.item.agent_name),
                          alt: "READY"
                        }
                      })
                    : _c("span", { staticClass: "text-nowrap" }, [
                        _vm._v(
                          _vm._s(data.item.ultimates.count) +
                            " / " +
                            _vm._s(data.item.ultimates.max)
                        )
                      ])
                ])
              : _c("span", [_vm._v("N/A")])
          ]
        }
      },
      {
        key: "cell(armor)",
        fn: function(data) {
          return [
            _c("div", { staticStyle: { "text-align": "center" } }, [
              data.value
                ? _c("img", {
                    staticClass: "table-icon",
                    attrs: {
                      src: data.item.armor_url,
                      alt: data.item.armor_name
                    }
                  })
                : _c("span", [_vm._v("-")])
            ])
          ]
        }
      },
      {
        key: "cell(weapon)",
        fn: function(data) {
          return [
            data.value
              ? _c("img", {
                  staticClass: "table-icon",
                  attrs: {
                    src:
                      "/images/weapons-white/" +
                      data.item.weapon_name.toLowerCase() +
                      ".png",
                    alt: data.item.weapon_name
                  }
                })
              : _c("span", [_vm._v("-")])
          ]
        }
      },
      {
        key: "cell(loadout)",
        fn: function(data) {
          return [
            _vm._v(" " + _vm._s(_vm._f("to_credits")(data.value)) + " "),
            _c("div", { staticClass: "small" }, [
              _vm._v(
                " " +
                  _vm._s(_vm._f("to_credits")(data.item.spent)) +
                  " / " +
                  _vm._s(_vm._f("to_credits")(data.item.remaining)) +
                  " "
              )
            ])
          ]
        }
      },
      {
        key: "table-busy",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "text-center text-danger my-2" },
              [
                _c("b-spinner", { staticClass: "align-middle" }),
                _c("strong", [_vm._v("Loading...")])
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }