var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.roundStats || !_vm.roundStats.length
        ? _c("div", { staticClass: "text-center text-muted mt-5 mb-5" }, [
            _vm._v(" No data for round " + _vm._s(_vm.roundNum + 1) + " ")
          ])
        : _vm._l(_vm.roundStats, function(team, team_id) {
            return _c(
              "div",
              { key: team_id, staticClass: "mt-5 mb-5" },
              [
                _c("TeamCell", {
                  staticClass: "ml-2 mb-2",
                  attrs: {
                    id: team.id,
                    image: team.icon_url,
                    link: "",
                    name: team.name,
                    variant: "row"
                  }
                }),
                _c("RoundStatsTable", {
                  class: team.role,
                  attrs: { items: team.players }
                })
              ],
              1
            )
          })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }