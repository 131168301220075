var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.apiParams
        ? _c("p", { staticClass: "text-danger" }, [
            _vm._v("Please, select a round from the timeline above")
          ])
        : _vm.loading
        ? _c("Loading")
        : _vm.error
        ? _c("p", { staticClass: "text-danger" }, [
            _vm._v(" " + _vm._s(_vm.error) + " ")
          ])
        : _c("MatchRoundStats", {
            attrs: { "round-num": _vm.roundNum, "round-stats": _vm.data }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }